import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as classnames from 'classnames';
import { connect } from 'react-redux';

import { i18n, Link, withTranslation } from '../../../common/helpers/i18n';
import { getApiImages, getAvatarLetters } from '../../../common/helpers/general';
import combineStyles from '../../../common/helpers/style';
import { generalStyles } from '../../../common/styles/generalStyles';
import { topBarProfileStyles } from './topBarProfileStyles';
import { logoutAction } from '../../../common/actions/authentication';
import { DEFAULT_ROLE_ROUTE } from '../../routes';

const TopBarProfile = props => {
  const { t, classes } = props;
  const avatarLetters = getAvatarLetters(props.userDetails.full_name);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.topbarWrapper}>
      {/* <div className={classes.notificationsWrapper}>
        <span className={classes.notifAlert} />
      </div> */}
      {props.userDetails && (
        <Fragment>
          <Avatar
            className={classnames(classes.avatarBg)}
            src={props.userDetails.image ? getApiImages(props.userDetails.image) : ''}
            alt={avatarLetters}
            aria-controls="topbar-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            {avatarLetters}
          </Avatar>
          <Menu
            id="topbar-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Link href={DEFAULT_ROLE_ROUTE}>
                <a href="" className={classes.menuLink}>
                  Profile
                </a>
              </Link>
            </MenuItem>
            <MenuItem>
              <span onClick={() => props.logout()}>Logout</span>
            </MenuItem>
          </Menu>
        </Fragment>
      )}
    </div>
  );
};

TopBarProfile.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});
const combinedStyles = combineStyles(generalStyles, topBarProfileStyles);

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logoutAction());
    },
  };
};

const mapStateToProps = state => ({
  user: state.user,
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)((withStyles(combinedStyles)(TopBarProfile))));
