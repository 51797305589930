import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { Link } from '../../../common/helpers/i18n';
import combineStyles from '../../../common/helpers/style';
import { generalStyles } from '../../../common/styles/generalStyles';
import { logoStyles } from './logoStyles';
import { HOMEPAGE_ROUTE } from '../../routes';

const Logo = (props) => {
  const { classes } = props;

  return (
    <Link href={HOMEPAGE_ROUTE}>
      <a>
        <Grid className={`${classes.logo} ${props.className}`} />
      </a>
    </Link>
  );
};

Logo.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});
const combinedStyles = combineStyles(generalStyles, logoStyles);

export default withStyles(combinedStyles)(Logo);
