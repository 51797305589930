import { themeVariables } from '../../../common/styles/themeVariables';

export const topBarProfileStyles = theme => ({
  topbarWrapper: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('xs')]: {
      float: 'right',
    },
  },
  avatar: {
    display: 'inline-block',
    float: 'right',
  },
  notificationsWrapper: {
    position: 'relative',
    width: '24px',
    height: '24px',
    display: 'inline-block',
    float: 'right',
    margin: '7px 10px',
    backgroundImage: 'url(images/ic-bell.png)',
    backgroundSize: '100%',
  },
  notifAlert: {
    width: '7px',
    height: '7px',
    backgroundColor: themeVariables.colors.accent,
    borderRadius: '50%',
    position: 'absolute',
    top: '-2px',
    right: '-2px',
  },
  menuLink: {
    textDecoration: 'none',
    color: themeVariables.colors.primaryText
  }
});
