import { themeVariables } from '../../styles/themeVariables';

export const logoStyles = theme => ({
  logo: {
    backgroundImage: 'url("/images/logo-white.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',

    '&.main': {
      width: '78px',
      height: '36px',
    },

    '&.dash': {
      width: '104px',
      height: '48px',
      margin: '15px 0 0 15px',
    },
  },
});
