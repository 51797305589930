import { makeStyles } from '@material-ui/core/styles';

import { themeVariables } from './themeVariables';

export const generalStyles = (theme) => ({
  greyBg: {
    backgroundColor: themeVariables.colors.background,
  },
  width100: {
    minWidth: '100% !important',
  },
  width50: {
    minWidth: '50% !important',
  },
  width50sm: {
    minWidth: '100% !important',
    [theme.breakpoints.up('sm')]: {
      minWidth: '50% !important',
    },
  },
  customContainer: {
    position: 'relative',
    width: '100%',
    padding: '0 15px',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '800px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '960px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1920px',
    },
  },
  hideXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  pullRight: {
    float: 'right',
  },
  modalCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .modalContainer': {
      width: '750px',
      '&.large': {
        width: '80%',
      },
      maxHeight: '90%',
      overflowY: 'auto',
      backgroundColor: themeVariables.colors.white,
      borderRadius: '16px',
      outline: 'none',
      padding: '21px 24px',
      [theme.breakpoints.only('sm')]: {
        width: '500px',
        maxHeight: '90vh',
        overflowY: 'scroll',
      },
      [theme.breakpoints.only('xs')]: {
        width: '340px',
        maxHeight: '90vh',
        overflowY: 'scroll',
      },
    },
  },
  modalCenterXs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .modalContainer': {
      width: '378px',
      backgroundColor: themeVariables.colors.white,
      borderRadius: '16px',
      outline: 'none',
      padding: '21px 24px',
      [theme.breakpoints.only('sm')]: {
        maxHeight: '90vh',
        overflowY: 'scroll',
      },
      [theme.breakpoints.only('xs')]: {
        maxHeight: '90vh',
        overflowY: 'scroll',
      },
    },
  },
  modalTitle: {
    marginBottom: '12px',
  },
  whiteCard: {
    backgroundColor: themeVariables.colors.white,
    borderRadius: '16px',
  },
  alignRight: {
    marginLeft: 'auto',
  },
  alignLeft: {
    marginRight: 'auto',
  },
  mainBtn: {
    backgroundColor: themeVariables.colors.primaryText,
    color: themeVariables.colors.white,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  buttons: {
    padding: '10px 45px',
  },
  mBottom30: {
    marginBottom: '30px',
  },
  endAdornmentTxt: {
    fontSize: '16px',
    color: themeVariables.colors.primaryPale,
    fontWeight: '400',
    padding: '0 12px 0 0',
    cursor: 'pointer',
  },
  blueButton: {
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    boxShadow:
      '0 8px 12px 0 rgba(31, 59, 99, 0.24), 0 4px 12px 0 rgba(31, 59, 99, 0.12)',
    backgroundColor: themeVariables.colors.primaryText,
  },
  pinkButton: {
    '&:hover': {
      backgroundColor: themeVariables.colors.accent,
      boxShadow:
        '0 4px 6px 0 rgba(255, 0, 136, 0.24), 0 2px 6px 0 rgba(255, 0, 136, 0.12)',
    },
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    boxShadow:
      '0 8px 12px 0 rgba(255, 0, 136, 0.24), 0 4px 12px 0 rgba(255, 0, 136, 0.12)',
    backgroundColor: themeVariables.colors.accent,
  },
  pacificBlueButton: {
    '&:hover': {
      backgroundColor: themeVariables.colors.mainPale,
      boxShadow:
        '0 4px 6px 0 rgba(0, 182, 183, 0.24), 0 2px 6px 0 rgba(0, 182, 183, 0.12)',
    },
    boxShadow:
      '0 8px 12px 0 rgba(0, 182, 183, 0.24), 0 4px 12px 0 rgba(0, 182, 183, 0.12)',
    backgroundColor: themeVariables.colors.mainPale,
  },
  pacificBlueButtonFullWith: {
    '&:hover': {
      backgroundColor: themeVariables.colors.mainPale,
      boxShadow:
        '0 4px 6px 0 rgba(0, 182, 183, 0.24), 0 2px 6px 0 rgba(0, 182, 183, 0.12)',
    },
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    boxShadow:
      '0 8px 12px 0 rgba(0, 182, 183, 0.24), 0 4px 12px 0 rgba(0, 182, 183, 0.12)',
    backgroundColor: themeVariables.colors.mainPale,
  },
  iconButton: {
    marginRight: 10,
    marginLeft: '-8px',
  },
  root: {
    width: '100%',
  },
  publicProfiles: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 1175,
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  minHeight300: {
    minHeight: '300px !important',
  },
  whiteBackground: {
    backgroundColor: themeVariables.colors.white,
  },
  noUnderlineLinks: {
    textDecoration: 'none',
  },
  alignContentCenter: {
    alignItems: 'center',
  },
  homepageSectionMargin: {
    padding: '40px 0',
  },
  avatarMedium: {
    width: 80,
    height: 80,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      width: 48,
      height: 48,
    },
  },
  avatarSmall: {
    width: 25,
    height: 25,
    fontSize: 12,
    fontWeight: 500,
  },
  contentCentered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarBg: {
    backgroundColor: themeVariables.colors.avatarBg,
  },
  textWhite: {
    color: themeVariables.colors.white,
  },
  event: {
    display: 'flex',
    alignItems: 'center',
  },
  eventTitle: {
    justifySelf: 'flex-start',
    flex: 1,
    fontSize: 12,
    lineHeight: '17px',
  },
  eventButton: {
    color: themeVariables.colors.white,
    justifySelf: 'flex-end',
    minWidth: '25px',
    padding: 0,
    zIndex: 1,
    '& svg': {
      fontSize: 17,
    },
  },
  textCentered: {
    textAlign: 'center',
  },
  avatarLarge: {
    width: 150,
    height: 150,
    fontSize: '40px',
  },
  searchListFiltersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: themeVariables.colors.secondaryText,
  },
  searchListFiltersTexts: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 500,
  },
  searchList: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchListItemNameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  searchListItemName: {
    color: themeVariables.colors.mainPale,
  },
  searchListItemRating: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '4px',
  },
  searchListItem: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottomWidth: 1,
    borderColor: themeVariables.colors.lines,
  },
  paddingRight16: {
    paddingRight: theme.spacing(2),
  },
  marginAuto: {
    margin: 'auto',
  },
  displayFlex: {
    display: 'flex',
  },
  verticalAlignCenter: {
    margin: 'auto 0',
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  displayFlexWithAlignContentCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const useGeneralStyles = makeStyles(generalStyles);
